"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.fetchTxTag = exports.tx = exports.all = exports.run = void 0;
var axios_1 = require("axios");
var tx_1 = require("./queries/tx");
var run = function (query, variables) { return __awaiter(void 0, void 0, void 0, function () {
    var graphql, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                graphql = JSON.stringify({
                    query: query,
                    variables: variables
                });
                return [4 /*yield*/, axios_1["default"].post("https://arweave.net/graphql", graphql, {
                        headers: {
                            "content-type": "application/json"
                        }
                    })];
            case 1:
                res = (_a.sent()).data;
                return [2 /*return*/, res];
        }
    });
}); };
exports.run = run;
var all = function (query, variables) { return __awaiter(void 0, void 0, void 0, function () {
    var hasNextPage, edges, cursor, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                hasNextPage = true;
                edges = [];
                cursor = "";
                _a.label = 1;
            case 1:
                if (!hasNextPage) return [3 /*break*/, 3];
                return [4 /*yield*/, exports.run(query, __assign(__assign({}, variables), { cursor: cursor }))];
            case 2:
                res = (_a.sent()).data.transactions;
                if (res.edges && res.edges.length) {
                    edges = edges.concat(res.edges);
                    cursor = res.edges[res.edges.length - 1].cursor;
                }
                hasNextPage = res.pageInfo.hasNextPage;
                return [3 /*break*/, 1];
            case 3: return [2 /*return*/, edges];
        }
    });
}); };
exports.all = all;
var tx = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var isBrowser, cache, res, cache;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                isBrowser = typeof window !== "undefined";
                if (isBrowser) {
                    cache = JSON.parse(localStorage.getItem("gqlCache") || "{}");
                    if (id in cache)
                        return [2 /*return*/, JSON.parse(cache[id])];
                }
                return [4 /*yield*/, exports.run(tx_1["default"], { id: id })];
            case 1:
                res = _a.sent();
                if (isBrowser && res.data.transaction.block) {
                    cache = JSON.parse(localStorage.getItem("gqlCache") || "{}");
                    cache[id] = res.data.transaction;
                    localStorage.setItem("gqlCache", JSON.stringify(cache));
                }
                return [2 /*return*/, res.data.transaction];
        }
    });
}); };
exports.tx = tx;
var fetchTxTag = function (id, name) { return __awaiter(void 0, void 0, void 0, function () {
    var res, tag;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, exports.tx(id)];
            case 1:
                res = _a.sent();
                tag = res.tags.find(function (tag) { return tag.name === name; });
                if (tag)
                    return [2 /*return*/, tag.value];
                return [2 /*return*/];
        }
    });
}); };
exports.fetchTxTag = fetchTxTag;
